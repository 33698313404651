.alertToast{
position: fixed;
top: 0;
left: 50%;
transform: translateY(-500px) translateX(-50%);
z-index: 999;
padding: 12px;
border-radius: 12px;
background: var(--dark-alert-tip, #323740);
box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.76);
font-size: 14px;
font-style: normal;
font-weight: 400;
color: var(--dark-text, #F6F6F6);
transition: transform 0.5s linear;
&.show{
    transform: translateY(10px) translateX(-50%);
}
&.hide{
    transform: translateY(-500px) translateX(-50%);
}
.d-flex{
    gap: 12px;
}

}

.closeToastBtn{
    background: transparent;
    border: 0;
    padding: 0;
}


