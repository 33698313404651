.settingsTabs{
    li{
        list-style-type: none;
        display: block;
        margin-bottom:16px;
        a{
            padding:8px 20px;
            background:transparent;
            display: inline-block;
            color: var(--dark-text, #F6F6F6);
            transition: all 0.5s;

            &:hover{
                border-radius: 8px;
                background: var(--dark-input-button, #1D2025);
                color:var(--Primary-1, #FFA800);
                cursor:pointer;

                svg{
                    path{
                        fill:var(--Primary-1, #FFA800);
                    }
                }
            }
        }
    }
}
.settingsHeading{
    color: var(--dark-text-secondary, #C5C5C5);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}
.ylwOlnBtn{
    display: flex;
    width: 112px;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Primary-1, #FFA800);
    background: var(--dark-input-button, #1D2025);
    color:var(--Primary-1, #FFA800);
    transition:all 0.5s;

    &:hover{
        background:var(--Primary-1, #FFA800);
        color:var(--dark-input-button, #1D2025);
    }
}

.mycollectionSec{
    .createCollectionCard{
        form{
            .urlFieldCnt{
                .input-group{
                    &.copyCnt{
                        input{
                            padding-left: 12px;
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                            border-left:inherit;
                            padding-right: 5px;
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;
                            border-right:0px;
                        }
                        .input-group-append{
                            .input-group-text{
                                padding-right:12px;
                                border-right:1px solid var(--dark-borders-hover-button, #2D3139);
                                border-top-left-radius: 0px;
                                border-bottom-left-radius: 0px;
                                border-top-right-radius: 8px;
                                border-bottom-right-radius: 8px;
                            }
                        }
                    }
                }
            }

            .connectTickCnt{
                overflow: hidden;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;

                .text-success{
                    color: var(--Green, #00B976);
                }
            }
            .input-group{
                &.verified{
                    input{
                        border-right:0px;
                    }
                    .input-group-append{
                        .input-group-text{
                            background:var(--dark-input-button, #1D2025);
                            border-left:0px;
                            border-color:var(--dark-borders-hover-button, #2D3139);
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                        }
                    }
                }
                .btn{
                    height:40px;
                }
            }
            .borderedCnt{
                &.offerRow{
                    .greyTxt{
                        span{
                            color:var(--dark-text-secondary, #C5C5C5);
                        }
                    }
                }
            }
        }

        .accordion{
            .accordion__item{
                border-radius: 12px;
                background: var(--dark-input-button, #1D2025);
                /* BG Blur - Button */
                backdrop-filter: blur(19px);
                margin-bottom:20px;

                .accordion__button{        
                    border-radius: 12px;        
                    padding:12px 16px;
                    background: var(--dark-input-button, #1D2025);
                    /* BG Blur - Button */
                    backdrop-filter: blur(19px);
                    overflow: hidden;
                    color: var(--dark-text, #F6F6F6);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    position:relative;

                    &::before{
                        display:none;
                    }
                    &::after{
                        content:'';
                        position:absolute;
                        top:12px;
                        right:16px;
                        width:12px;
                        height:12px;
                        background:url('../../assets/images/angleDown.svg') no-repeat center center;
                        background-size:contain;
                    }

                    &[aria-expanded="true"]{
                        &::after{
                            transform: rotate(180deg);
                        }
                    }
                }

                .accordion__panel{
                    padding-top:0px;

                    p{
                        color: var(--dark-text, #F6F6F6);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 24px;    
                    }                    
                }
            }
            
        }
    }
}
