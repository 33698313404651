@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&family=Russo+One&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}


:root{
	--font-poppins: 'Poppins', sans-serif;
	--font-russo: 'Russo One', sans-serif;
	--dark-white: #FFFFFF;
	--dark-bg-1: #0A0A0B;	
	--dark-bg-2: #14161B;
	--dark-input-button: #1D2025;
	--dark-text-secondary: #C5C5C5;
	--dark-text-grey: #797F8A;
	--dark-borders-hover-button: #2D3139;
	--dark-alert-tip: #323740;
	--dark-gradient: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%, #FFF6A4 95.08%);
}

body{
	background:var(--dark-bg-1);
	color:#fff;
	font-family:var(--font-poppins);
}


/** Common Styles **/
@media only screen and (min-width: 1200px)
{
	.container
	{
		max-width: 1346px;
	}
}

/*Home page style*/
.collectionActivitySec{
	padding:60px 0px;
	position:relative;

	.container{
		z-index: 3;
		position: relative;
	}

	&:before{
		content:'';
		position: absolute;
		right:0px;
		top:0px;
		width:100%;
		height:100%;
		background: url('/assets/images/colActBg1.png') no-repeat right top;
		background-size:contain;
		z-index: 2;
	}
	&:after{
		content:'';
		position: absolute;
		left:0px;
		bottom:0px;
		width:100%;
		height:100%;
		background: url('/assets/images/colActBg2.png') no-repeat left bottom;
		background-size:contain;
		z-index: 2;
	}

	.card{
		background:#15171C;
		border-radius: 20px;
		padding:28px;
		width:100%;

		.nav-tabs{
			border-radius: 12px;
			background: #191D22;
			height:40px;
			backdrop-filter: blur(19px);
			border-bottom:0px;

			li{
				a{
					color: var(--dark-text-secondary);
					text-align: center;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px;
					opacity:0.6;
					border:0px;

					&:hover,&.active{
						border-radius: 12px;
						background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
						color: var(--dark-bg-1);
						text-align: center;
						font-size: 16px;
						font-style: normal;
						line-height: 24px;
						border:0px;
						opacity:1;
					}
					&.active{
						font-weight: 600;
					}
					&:hover{
						cursor: pointer;
					}
				}
			}

			&.checkTab{
				li{
					a{
						&:hover,&.active{
							background: #2E343D;
							backdrop-filter: blur(19px);
							color: #FFA900;
						}
					}
				}
			}

			&.threeTab{
				width:100%;
				li{
					width:33.33%;
				}
			}
		}

		select{
			padding:8px 16px;
			padding-right:40px;
			border-radius: 12px;
			border: 1px solid #2E343D;
			background: #191D22 url('/assets/images/angleDown.svg') no-repeat right 16px center;
			appearance:none;
			font-size: 16px;
			line-height: 24px;
			color:#f6f6f6;
			height:40px;

			@media(max-width:767px){
				margin-top:15px;
			}
		}

		table{
			thead{
				th{
					color: #595F6A;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px;
					border-bottom:0px;
					border-top:0px;
					padding:0.75rem 0.4rem;

					@media(max-width:767px){
						white-space: nowrap;
					}
				}
			}
			tbody{
				tr{
					
					td{
						vertical-align: middle;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px;
						color:#f6f6f6;
						border-top:0px;
						padding:0.75rem 0.4rem;
						h3{
							font-size: 16px;
							font-style: normal;
							font-weight: 600;
							line-height: 24px;
							color:#f6f6f6;
							margin-bottom:0px;
						}

						&:first-child{
							border-top-left-radius:20px;
							border-bottom-left-radius:20px;
						}
						&:last-child{
							border-top-right-radius:20px;
							border-bottom-right-radius:20px;
						}
						&:hover{
							background-color:transparent;

							td{
								background-color:#191D22;
							}
						}
						.labelCnt{
							min-height: 28px;
							padding: 4px 8px;
							border-radius: 8px;
							background: #191D22;
						}

						@media(max-width:767px){
							white-space: nowrap;
						}
					}
				}
				
				.smTxt{
					font-size: 12px;
				}
				.lgTxt{
					font-size: 20px;
				}
			}
		}
	}

	@media(min-width:1200px){
		padding-bottom:200px;
	}
}

.tabSliderSec{
	position:relative;
	z-index: 4;
	.card{
		padding:0px;
		border:none;
		width:100%;
		overflow:hidden;
		position:relative;
		border-radius:16px;

		img{
			&.banner{
				width:100%;
				height:100%;
				object-fit: cover;
				border-radius:16px;
			}
		}

		.infoCnt{
			width:100%;
			padding:12px;
			fill: rgba(13, 13, 13, 0.80);
			background: rgba(13, 13, 13, 0.80);
			backdrop-filter: blur(19px);
			position:absolute;
			left:0px;
			bottom:0px;

			h3{
				&.heading{
					color:#f6f6f6;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px;
					margin-bottom:0px;
					margin-right:5px;

					@media(max-width:991px){
						font-size:14px;
					}
				}
			}

			.valueCnt{
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				color:#e5e5e5;

				span{
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
					color:#f6f6f6;
				}
			}
		}
	}

	.nav-tabs{
		border-radius: 12px;
		background: #191D22;
		height:36px;
		backdrop-filter: blur(19px);
		border-bottom:0px;
		width: auto;
    	display: inline-flex;

		@media(max-width:992px){
			height:auto;
			justify-content: center;
		}

		li{
			a{
				color: var(--dark-text-secondary);
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				opacity:0.6;
				border:0px;
				padding:6px 20px;

				&:hover,&.active{
					border-radius: 12px;
					background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
					color: var(--dark-bg-1);
					text-align: center;
					font-size: 16px;
					font-style: normal;
					line-height: 24px;
					border:0px;
					opacity:1;
				}
				&.active{
					font-weight: 600;
				}
				&:hover{
					cursor: pointer;
				}
			}
		}
	}

	.slick-slider{

		.slick-list{
			min-height:318px;
			@media(max-width:992px){
				padding-top:30px;
			}
		}
		.slick-next{
			width:40px;
			height:40px;
			background:#191D22;
			border-radius:12px;
			position:absolute;
			top:-45px;
			right:0px;

			@media(max-width:992px){
				top:0px;
				right:0px;
			}

			img{
				transform: rotate(-90deg);
				opacity:0.5;
			}

			&:before{
				display:none;
			}

			&:hover{
				img{
					opacity: 1;
				}
			}
		}

		.slick-prev{
			width:40px;
			height:40px;
			background:#191D22;
			border-radius:12px;
			position:absolute;
			top:-45px;
			right:50px;
			left:auto;

			@media(max-width:992px){
				top:0px;
				right:50px;
			}

			img{
				transform: rotate(90deg);
				opacity:0.5;
			}

			&:before{
				display:none;
			}

			&:hover{
				img{
					opacity: 1;
				}
			}
		}

		/* the slides */
		.slick-slide {
			padding: 0 14px;
		}
	}

	@media(min-width:1200px){
		margin-top:-150px;
	}
	 
}

.FeaturedSec{
	padding:80px 0px;
	padding-top:100px;
	background:url('/assets/images/featuredBg.png') no-repeat right top -50px;
	background-size:contain;
	.card{
		padding:0px;
		border:none;
		width:100%;
		overflow:hidden;
		position:relative;
		border-radius:16px;

		img{
			&.banner{
				width:100%;
				height:320px;
				object-fit: cover;
				border-radius:16px;
			}
		}

		.infoCnt{
			width:100%;
			padding:12px;
			fill: rgba(13, 13, 13, 0.80);
			background: rgba(13, 13, 13, 0.80);
			backdrop-filter: blur(19px);
			position:absolute;
			left:0px;
			bottom:0px;

			.infoTop{
				margin-top:-50px;
			}

			h3{
				&.heading{
					color:#f6f6f6;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px;
					margin-bottom:0px;
					margin-right:5px;

					@media(max-width:991px){
						font-size:14px;
					}
				}
			}

			.valueCnt{
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				color:#e5e5e5;

				span{
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
					color:#f6f6f6;
				}
			}

			.profPic{
				width:80px;
				height:80px;
				object-fit: contain;
			}
		}
	}

	.slick-slider{

		.slick-list{
			min-height:318px;
			@media(max-width:992px){
				padding-top:30px;
			}
		}
		.slick-next{
			width:40px;
			height:40px;
			background:#191D22;
			border-radius:12px;
			position:absolute;
			top:-45px;
			right:0px;

			@media(max-width:992px){
				top:0px;
				right:0px;
			}

			img{
				transform: rotate(-90deg);
				opacity:0.5;
			}

			&:before{
				display:none;
			}

			&:hover{
				img{
					opacity: 1;
				}
			}
		}

		.slick-prev{
			width:40px;
			height:40px;
			background:#191D22;
			border-radius:12px;
			position:absolute;
			top:-45px;
			right:50px;
			left:auto;

			@media(max-width:992px){
				top:0px;
				right:50px;
			}

			img{
				transform: rotate(90deg);
				opacity:0.5;
			}

			&:before{
				display:none;
			}

			&:hover{
				img{
					opacity: 1;
				}
			}
		}

		/* the slides */
		.slick-slide {
			padding: 0 14px;
		}
	}
}

.notableSec{
	padding:80px 0px;
	padding-top:150px;
	background:url('/assets/images/notableBg.png') no-repeat left -150px bottom -50px;
}
.gradientHeading{
	font-family: Russo One;
	font-size: 40px;
	font-style: normal;
	font-weight: 400;
	line-height: 60px;
	text-shadow: 0px 0px 12px rgba(246,246,246,0.3);
	background: var(--dark-gradient, linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%));
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	@media(max-width:767px){
		font-size: 30px;
	}
}
.gradientBtn{
	display: flex;
	padding: 8px 20px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 12px;
	background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
	color: var(--dark-bg-1);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	transition: all 0.5s;

	&:hover{
		background: linear-gradient(137deg, #191D22 6.3%, #2E343D 95.08%);
		color:#FFA900;
	}
}
.darkBtn{
	display: flex;
	min-width: 160px;
	height: 50px;
	padding: 8px 20px;
	justify-content: center;
	align-items: center;
	color:#fff;
	border-radius: 8px;
	font-weight: 600;
	background: #1F2329;transition: all 0.5s;

	&:hover{
		background: #FFA900;
		color:#1F2329;

		svg{
			path{
				fill:var(--dark-bg-1)!important;
			}
		}
	}
}
.btn160{
	min-width:160px;
}
.text-danger{
	color:#FA0B00!important;
}
.text-success{
	color:#1BFA70!important;
}
.ylwTxt{
	color:#FFDF6F!important;
}
.yellowTxt{
	color:#FFA900!important;
}
.gryTxt{
	color:var(--dark-text-secondary)!important;
}
.boldTxt{
	font-weight:600!important;
}
.altFont{
	font-family:var(--font-russo)!important;
}
/*E O Home page style*/

.walletIconCnt{
	width:30%;
	max-width:190px;
	padding:20px;
	display: flex;
	padding: 20px;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	flex: 1 0 0;
	border-radius: 16px;
	border: 2px solid #2E343D;
	background:#15171C;
	margin:0px 2%;
	position:relative;
	transition: all 0.2s;

	&:before{
		content:'';
		position:absolute;
		width:100%;
		height:100%;
		left:0px;
		top:0px;
		border-radius: 12px;
		background:#15171C;
		z-index: 2;
		transition: all 0.2s;
	}
 
	h3{
	   &.heading{
		  color: var(--dark-text-secondary);
		  text-align: center;
		  font-size: 16px;
		  font-style: normal;
		  font-weight: 600;
		  line-height: 24px;
		  margin-bottom:0px;
		  position: relative;
		  z-index: 3;
	   }
	}
	img{
		position: relative;
		z-index: 3;
	}

	&:hover{
		background: rgb(255,169,0);
		background: linear-gradient(134deg, rgba(255,169,0,1) 0%, rgba(255,255,255,0) 50%, rgba(255,237,174,1) 100%);
		cursor:pointer;
		&:before{
			width:calc(100% - 4px);
			height:calc(100% - 4px);
			top:2px;
			left:2px;
		}
	}
	
 }

 @keyframes loader {

	0%   {transform: rotate(0deg);}
	25%  {transform: rotate(180deg);}
	50%  {transform: rotate(360deg);}
	75%  {transform: rotate(180deg);}
	100% {transform: rotate(0deg);}
  }

 .loaderCont{
	position:relative;

	.loaderAnimCnt{
		position:absolute;
		left:50%;
		top:50%;
		transform: translate(-21px , -15px);
		z-index: 3;

		img{
			animation: loader 3s infinite;
		}
		
	}
 }
 .tooltipCnt{
	background-color:var(--dark-alert-tip);
 }

 // *******************hometrending***********************

.mb-36 {
	margin-bottom: 36px;
}

.mb-20 {
	margin-bottom: 20px;
}

.TraSec {
	padding: 30px 0px;
   padding-top:90px;
}

.trad_tab_hd {
	border-radius: 12px;
	background: #191D22;
	backdrop-filter: blur(19px);
   border-bottom:0px;

	li {
		margin-right: 5px;
		&:last-child{
			margin-right: 0px;
		}
		a {
			border-radius: 12px;
			text-align: center;
			font-size: 16px;
			font-style: normal;
			border: none;
			color: var(--dark-text-secondary);
			font-weight: 600;

			box-shadow: none;

			&:hover,
			&.active {
				background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
				color: var(--dark-bg-1)!important;
				font-weight: 600;
				transition: 0.5s ease-in-out;
				box-shadow: none;
            border:none;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
			}

         &:hover{
            cursor:pointer;
         }

		}
	}
}

// .hrs_tab_hd {
// 	border-radius: 12px;
// 	background: #191D22;
// 	backdrop-filter: blur(19px);
//    border-bottom:0px;

// 	li {
// 		a {
// 			color: var(--dark-text-secondary);
// 			text-align: center;
// 			font-size: 16px;
// 			font-style: normal;
// 			font-weight: 600;
// 			background-color: transparent;
// 			box-shadow: none;
// 			&:hover,
// 			&.active {
// 				border-radius: 12px;
// 				background: #2E343D!important;
// 				color: #FFA900!important;
// 				box-shadow: none;
//             border:none;

// 			}

//          &:hover{
//             cursor:pointer;
//          }
// 		}
// 	}
// }


.artcrd {
	border-radius: 8px;
	background: #191D22;
	color: #F6F6F6;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	padding: 2px 7px;
	margin-bottom: 10px;
	cursor: pointer;

	span {
		padding: 5px;
	}

	.crs {
		cursor: pointer;
	}
}

.filtl {
	border-radius: 16px;
	background: #15171C;
	padding: 20px 20px;

	h1 {
		color: var(--dark-text-secondary);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		margin-bottom: 20px;
	}

	h2 {
		color: #F6F6F6;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		margin-bottom: 0px;
	}
}

.hdfil {
	.rntar {
		transition: 0.6s ease-in-out;
	}

}

.hdfil[aria-expanded="true"] {

	.rntar {
		rotate: 180deg;
		transition: 0.6s ease-in-out;
	}

}

.bxnflt {
	border-radius: 12px;
	background: #191D22;
	padding: 16px 16px;

	h3 {
		color: #F6F6F6;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
	}


}

// .cuschk {


// 	display: block;
// 	position: relative;
// 	cursor: pointer;
// 	height: 20px;
// 	width: 20px;
// 	-webkit-user-select: none;
// 	-moz-user-select: none;
// 	-ms-user-select: none;
// 	user-select: none;


// 	/* Hide the browser's default checkbox */
// 	input {
// 		position: relative;
// 		opacity: 0;
// 		cursor: pointer;
// 		height: 0;
// 		width: 0;
// 	}

// 	/* Create a custom checkbox */
// 	.checkmark {
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		height: 20px;
// 		width: 20px;
// 		background-color: #191D22;
// 		border: 1px solid #2E343D;
// 		border-radius: 3px;
// 	}

// 	/* On mouse-over, add a grey background color */
// 	&:hover input~.checkmark {
// 		background-color: #181B20;
// 		border: 1px solid #2E343D;
// 		border-radius: 3px;
// 	}

// 	/* When the checkbox is checked, add a blue background */
// 	input:checked~.checkmark {
// 		background-color: #FFA900;
// 		border: 1px solid #FFA900;
// 		border-radius: 3px;
// 	}

// 	/* Create the checkmark/indicator (hidden when not checked) */
// 	.checkmark:after {
// 		content: "";
// 		position: absolute;
// 		display: none;
// 	}

// 	/* Show the checkmark when checked */
// 	input:checked~.checkmark:after {
// 		display: block;
// 	}

// 	/* Style the checkmark/indicator */
// 	.checkmark:after {
// 		left: 7px;
// 		top: 3px;
// 		width: 5px;
// 		height: 10px;
// 		border: solid var(--dark-bg-1);
// 		border-width: 0 1.5px 1.5px 0;
// 		-webkit-transform: rotate(45deg);
// 		-ms-transform: rotate(45deg);
// 		transform: rotate(45deg);
// 	}
// }


.bri_pagenation2 {
	margin: 20px 0px;

	.pagination {
		.page-link {
			border-radius: 2px;
			background: #15171C;
			border: none;
			color: #FFF;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			margin: 2px;
			border-radius: 12px;
			padding: 10px;
			margin: 6px;
			min-width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
				color: #000;

			}
		}

		.page-link-1 {

			&:hover {
				svg {
					path {
						fill: #000;
					}
				}
			}
		}

		.clog {
			margin: 6px;
			color: #595F6A;
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;

		}
	}
}

.nthhkj {

	h1 {
		color: #F6F6F6;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
	}

	p {
		color: var(--dark-text-secondary);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		max-width: 230px;
		margin: auto;
	}

}


@media only screen and (max-width: 991px) {
	.mb-36 {
		margin-bottom: 16px;
	}

	.bri_pagenation2 .pagination .page-link {
		min-width: 35px;
		min-height: 35px;
		margin: 3px;
		padding: 5px;
	}
}


// *******************hometrending***********************

.mb-36 {
	margin-bottom: 36px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mt-20 {
	margin-top: 20px;

}

.TraSec {
	padding: 30px 0px;
	padding-top:90px;
}

.trad_tab_hd {
	border-radius: 12px;
	background: #191D22;
	backdrop-filter: blur(19px);
	min-height: 40px;
	li.nav-item {
		a.nav-link {
			border-radius: 12px;
			text-align: center;
			font-size: 16px;
			font-style: normal;
			border: 0px;
			color: var(--dark-text-secondary);
			font-weight: 600;
			transition: 0.5s ease-in-out;
			box-shadow: none;
			&:hover,
			&.active {
				background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
				color: var(--dark-bg-1);
				font-weight: 600;				
				box-shadow: none;
			}

		}
	}
}

.hrs_tab_hd {
	border-radius: 12px;
	background: #191D22;
	backdrop-filter: blur(19px);
	border: 0px;
	min-height: 40px;
	li.nav-item {
		a.nav-link {
			color: var(--dark-text-secondary);
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			background-color: transparent;
			box-shadow: none;
			border-radius: 12px;
			border: 0;
			&:hover,
			&.active {				
				background: #2E343D;
				color: #FFA900;
				box-shadow: none;
			}
		}
	}
}

.trd_head {
	tr {
		th {
			color: #595F6A;
			text-align: right;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			white-space: nowrap;

			&:nth-child(1) {
				text-align: start;
			}

			&:nth-child(2) {
				text-align: start;
			}
		}
	}
}

.trdtable_body {
	tr {
		td {
			color: #F6F6F6;
			text-align: right;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			white-space: nowrap;
			vertical-align: middle;
			&:first-child{
				border-top-left-radius: 12px;
				border-bottom-left-radius: 12px;
			}
			&:last-child{
				border-top-right-radius: 12px;
				border-bottom-right-radius: 12px;
			}

			&:nth-child(1) {
				text-align: start;				
			}

			&:nth-child(2) {
				text-align: start;
			}

			&.red {
				color: #FA0B00 !important;
			}

			&.green {
				color: #1BFA70 !important;
			}

			&.gld {
				color: #FFDF6F;
				text-align: center;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
			}

			&.orncl {
				color: #FFA900;
				text-overflow: ellipsis;
				overflow: hidden;
				max-width: 120px;
			}

			h1 {
				overflow: hidden;
				color: #F6F6F6;
				text-overflow: ellipsis;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				max-width: 107px;
				margin-bottom: 0px;
			}

			h2 {
				color: #F6F6F6;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				margin-bottom: 0px;

			}

			p {
				color: var(--dark-text-secondary);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				margin-bottom: 0px;
			}

			h3 {
				color: #F6F6F6;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				margin-bottom: 0px;
			}


		}
		&:hover{
			background-color: var(--dark-bg-2);
		}
	}

}

.artcrd {
	border-radius: 8px;
	background: #191D22;
	color: #F6F6F6;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	padding: 2px 7px;
	margin-bottom: 10px;
	cursor: pointer;

	span {
		padding: 5px;
	}

	.crs {
		cursor: pointer;
	}
}

.filtl {
	border-radius: 16px;
	background: #15171C;
	padding: 20px 20px;

	h1 {
		color: var(--dark-text-secondary);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		margin-bottom: 20px;
	}

	h2 {
		color: #F6F6F6;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		margin-bottom: 0px;
	}
}

.hdfil {
	.rntar {
		transition: 0.6s ease-in-out;
	}

}

.hdfil[aria-expanded="true"] {

	.rntar {
		rotate: 180deg;
		transition: 0.6s ease-in-out;
	}

}

.bxnflt {
	border-radius: 12px;
	background: #191D22;
	padding: 16px 16px;
	max-height: 276px;
	overflow: auto;

	h3 {
		color: #F6F6F6;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
	}



}

.bxnflt {
	scrollbar-width: thin;
	scrollbar-color: #2E343D transparent;
}

.bxnflt::-webkit-scrollbar {
	width: 4px;
	height: 8px;
}

.bxnflt::-webkit-scrollbar-track {
	background-clip: content-box;
	border: 2px solid transparent;
}

.bxnflt::-webkit-scrollbar-thumb {
	background-color: #2E343D;
}

.bxnflt::-webkit-scrollbar-thumb:hover {
	background-color: #2E343D;
}

.bxnflt::-webkit-scrollbar-corner,
.bxnflt::-webkit-scrollbar-track {
	background-color: transparent;
}


.cuschk {


	display: block;
	position: relative;
	cursor: pointer;
	height: 20px;
	width: 20px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;


	/* Hide the browser's default checkbox */
	input {
		position: relative;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #191D22;
		border: 1px solid #2E343D;
		border-radius: 3px;
	}

	/* On mouse-over, add a grey background color */
	&:hover input~.checkmark {
		background-color: #181B20;
		border: 1px solid #2E343D;
		border-radius: 3px;
	}

	/* When the checkbox is checked, add a blue background */
	input:checked~.checkmark {
		background-color: #FFA900;
		border: 1px solid #FFA900;
		border-radius: 3px;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	input:checked~.checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.checkmark:after {
		left: 7px;
		top: 3px;
		width: 5px;
		height: 10px;
		border: solid var(--dark-bg-1);
		border-width: 0 1.5px 1.5px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}




.nthhkj {

	h1 {
		color: #F6F6F6;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
	}

	p {
		color: var(--dark-text-secondary);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		max-width: 230px;
		margin: auto;
	}

}


@media only screen and (max-width: 991px) {
	.mb-36 {
		margin-bottom: 16px;
	}
	
	.trad_tab_hd li.nav-item a.nav-link{
		font-size: 14px;
	}
}

// ****************homeactive*******************

.liscdbs {
	background-color: #191D22;
	padding: 8px 8px;
	border-radius: 8px;
	margin-bottom: 0px;

}

.sergps {
	border-radius: 12px;
	border: 1px solid #2E343D;
	background: #191D22;

	input {
		background: transparent;
		border: none;
		box-shadow: none;
		color: var(--dark-white);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		min-height: 40px;

		&:focus {
			background: transparent;
			border: none;
			box-shadow: none;
			color: var(--dark-white);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			min-height: 40px;

		}
	}

	.input-group-text {
		background: transparent;
		border: none;
	}
}

.noResf {
	h1 {
		color: #F6F6F6;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		margin-bottom: 8px;
	}

	p {
		color: var(--dark-text-secondary);
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		margin-bottom: 8px;

	}
}

.tooltip-inner{
	background-color:var(--dark-alert-tip)!important;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: var(--dark-alert-tip)!important; /* Red */
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before{
	border-top-color: var(--dark-alert-tip)!important;
}
.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before{
	border-left-color: var(--dark-alert-tip)!important;
}
.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before{
	border-right-color: var(--dark-alert-tip)!important;
}

.red-tooltip + .tooltip.top > .tooltip-arrow {background-color: var(--dark-alert-tip)!important;}


.fs-12{
	font-size: 12px;
}


.fs-14{
    font-size: 14px;
}
.fw-400{
    font-weight: 400;
}
.fs-16{
    font-size: 16px;
}
.fw-600{
    font-weight: 600;
}
.fw-700{
    font-weight: 700;
}


.dark-text-secondary{
    color: var(--dark-text-secondary, #C5C5C5) !important;
}
.dark-text{
    color: var(--dark-text, #F6F6F6) !important;
}
.dark-primary{
    color: var(--dark-primary-1, #FFA900) !important;
}
.whiteTxt{
	color:#fff!important;
}

.hover{
	cursor: pointer;
}
.custom-switch.customSwitch {
    .custom-control-label{
        &::before{
            width: 3rem;
            height: 1.5rem;
            border-radius: 50px;
			background-color: var(--light-text);
			border: 0;
        }
		&::after{
			width: 20px;
			height: 20px;
			top: calc(0.3rem + 2px);
    		left: calc(-2.1rem + 2px);
			background-color: var(--dark-white);
		}
    }
	.custom-control-input:checked~.custom-control-label{
		&::before{
			background-color: var(--Primary-1);
		}
		&::after{
			transform: translateX(1.5rem);
		}
	}
}
/*Light Mode style*/
:root{
	--light-grey: #21252B;
	--dark-white: #FFFFFF;
	--light-bg-1: #FFF;
	--dark-bg-1: #0A0A0B;
	--light-input-button: #E0E3E7;
	--light-text: #14161B;
	--light-text-secondary: #474C55;
	--light-text-grey: #73767D;
	--light-borders-hover-button: #D5D8DC;
	--light-bg-2: #F2F3F5;
	--light-alert-tip: #E4E6E9;
	--light-gradient-light: linear-gradient(137deg, #FFAB00 6.3%, #FFD76F 95.08%);
	--Primary-1: #FFA800;
}
body.light-mode{
	background-color: var(--dark-white);
	color: var(--dark-bg-1);

	.collectionActivitySec .card{
		background: var(--light-bg-2);
		border-color: transparent;
		.nav-tabs{
			background: var(--light-input-button);
			li{
				a{
					color: var(--light-text-grey);
					background-color: transparent;
					&.active,&:hover {
						background: var(--light-gradient-light);
						color: var(--Button-Text, #0A0A0B);
					}
				}
				&:last-child{
					a{
						margin-right: 0px;
					}
				}
			}
			&.checkTab {
				li{
					a{
						&:hover, &.active{
							background: var(--light-borders-hover-button);
							color: var(--Primary-1, #FFA800);
						}
					}
				}
			}
		}
		select{			
			border: 1px solid var(--light-borders-hover-button);
			background: var(--light-input-button) url('/assets/images/angleDown-dark.svg') no-repeat right 16px center;
			color: var(--light-text, #14161B);
		}
		table tbody tr td{
			color: var(--light-text);
			h3 {
				color: var(--light-text);
			}
			.labelCnt{
				background: var(--light-input-button);
				img{
					filter: brightness(0.5);
				}
			}
		}
	}
	.gradientHeading{
	background: var(--light-gradient-light);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	}
	.gradientBtn{
		background: var(--light-gradient-light);
		&:hover{
			color: var(--light-text);
		}
	}
	.ylwTxt {
    	color: var(--Primary-1) !important;
	}
	.gryTxt {
		color: var(--light-text-secondary) !important;
	}
	.tabSliderSec .nav-tabs{
			background: var(--light-input-button);
			li{
				a{
					color: var(--light-text-grey);
					background-color: transparent;
					&.active,&:hover {
						background: var(--light-gradient-light);
						color: var(--Button-Text, #0A0A0B);
					}
				}
				&:last-child{
					a{
						margin-right: 0px;
					}
				}
			}
			&.checkTab {
				li{
					a{
						&:hover, &.active{
							background: var(--light-borders-hover-button);
							color: var(--Primary-1, #FFA800);
						}
					}
				}
			}
		}

		.tabSliderSec, .FeaturedSec{
			.slick-slider{
				.slick-prev, .slick-next{
					background: var(--light-input-button);
					img{
						filter: brightness(0);
					}
				}
			}
		}		
		
		.tabSliderSec, .FeaturedSec{
			.card{
				 .infoCnt{
					background: rgba(255, 255, 255, 0.80);
					fill: rgba(255, 255, 255, 0.80);
					 h3.heading{
						color: var(--light-text);
					 }
					 .valueCnt{
						color: var(--light-text-secondary);
						span{
							color: var(--light-text);
						 }
					 }
				}
			}
		}
	.dark-text-secondary{
		color: var(--light-text-secondary) !important;
	}
	.dark-text{
		color: var(--light-text) !important;
	}
	.trad_tab_hd{
		background: var(--light-input-button);
		li.nav-item {
			a.nav-link {
				color: var(--light-text-grey);
				background-color: transparent;				
			}
		}
	}
	.hrs_tab_hd  {
		background: var(--light-input-button);
		li.nav-item {
			a.nav-link {
				color: var(--light-text-grey);
				background-color: transparent;	
				&:hover, &.active{
					background-color: var(--light-text-grey);
					color: var(--Primary-1);
				}			
			}
		}
	}
	.artcrd{
		background: var(--light-input-button);
		color: var(--light-text);
	}
	.filtl{
		background-color: var(--light-bg-2);
		h1 {
			color: var(--light-text-secondary);
		}
		h2{
			color: var(--light-text);
			& + img{
				filter: brightness(0);
			}
		}
		.bxnflt{
			background-color: var(--light-bg-1);
			h3{
				color: var(--light-text-grey);	
			}
		}
	}
	.cuschk .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #e0e3e7;
        border: 1px solid #d5d8dc;
        border-radius: 3px;
    }
	.cuschk + h3 {
		color: var(--light-text);
	}
	.hmecollection{		
        .trd_head tr th{
            color: var(--light-text-secondary);
         }
		 .trdtable_body tr{
			&:hover{
				background-color: var(--light-bg-2);
			}
		}
         .trdtable_body tr td {
            color: var(--light-text);
			h1{
				color: var(--light-text-secondary);
			}
         }
         .trdtable_body tr td button{
            background: var(--light-input-button);
            color: var(--light-text);
            &:hover{
               background: var(--light-gradient-light);         
            }
         }
		 .trdtable_body .liscdbs{
			background-color: var(--light-bg-2);
			color: var(--light-text);
			img{
				filter: brightness(0.3);
			}
		 }
		  .tableTag{
			background: var(--light-input-button);
			color: var(--light-text);
			img{
				filter: brightness(0.3);
			}
			svg path[fill="white"]{
				fill: var(--light-text);
			}
		  }
		 .trdtable_body tr td h3, 
		 .trdtable_body tr td h2{
			color: var(--light-text);
		 }
		 .trdtable_body tr td p {
			color: var(--light-text-secondary);
		 }
    }
	
.bri_pagenation2 {
	.pagination {
		.page-link {			
			background: var(--light-input-button);			
			color: var(--light-text);
			svg {
				path {
					fill: var(--light-text-secondary);
				}
			}
			&:hover {
				background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
				color: var(--dark-text);
			}
		}

		.clog {			
			color: var(--light-text);
		}
	}
}

}
/*Light Mode style*/

