.errNotFoundCnt{
    margin-top:72px;
    padding:15px;
    background:url('../../assets/images/notFoundBG.png') no-repeat center center;
    background-size: cover;
    min-height:650px;
    position:relative;

    .NFImg{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50% , -50%);
        max-width:90%;
        z-index: 2;
    }
    .textCnt{
        position: relative;
        z-index: 3;

        h3{
            &.heading{
                color: var(--White, #FFF);
                text-align: center;
                font-family: Russo One;
                font-size: 48px;
                font-style: normal;
                font-weight: 400;
                line-height: 60px;

                @media(max-width:991px){
                    font-size: 36px;
                    line-height: 50px;
                }
                @media(max-width:767px){
                    font-size: 30px;
                    line-height: 36px;
                }
            }

            .gradientTxt{
                background: var(--dark-gradient, linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%, #FFF6A4 95.08%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        p{
            color: var(--White, #FFF);
            text-align: center;
            font-family: Russo One;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;

            @media(max-width:991px){
                font-size: 18px;
            }
            @media(max-width:767px){
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}