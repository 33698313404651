
.tilebox{
    border-radius: 12px;
    background: var(--dark-bg-2, #14161B);
    border: 1px solid var(--dark-bg-2, #14161B);
    backdrop-filter: blur(19px);
    text-align: center;
    padding: 16px;
    margin-bottom: 28px;
    min-height: 112px;
    cursor: pointer;
    .iconwizard{
        border-radius: 8px;
        background: var(--dark-input-button, #1D2025);
        padding: 10px;
        width: 40px;
        height: 40px;       
        margin:  0 auto 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.3s linear;
    }
    .tileName{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        color: var(--dark-text, #F6F6F6);
    }
    &:hover{
        border-color: var(--Primary-1);
        .iconwizard{
            transform: scale(1.1);
        }
    }
}

.verticalline{
    margin: 0px 12px;
    display: inline-block;
    border-left: 2px solid var(--dark-input-button);
    height: 15px;
    vertical-align: middle;
}
.hpCateActive{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: var(--dark-text-secondary, #C5C5C5);
    .active{
        color: var(--dark-text, #F6F6F6);
        font-weight: 700;
    }
}

.quesListItem{
border-radius: 12px;
background: var(--dark-bg-2, #14161B);
backdrop-filter: blur(19px);
font-size: 16px;
font-style: normal;
font-weight: 600;
color: var(--dark-text, #F6F6F6);
padding: 12px;
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 20px;
}


.fiterSelectlabel{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .tagLabel{
        border-radius: 8px;
        background: var(--dark-input-button, #191D22);
        min-height: 28px;
        padding: 4px 8px;
        display: inline-flex;
        align-items: center;
        margin-right: 10px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: var(--dark-text, #F6F6F6);
        margin-bottom: 8px;
        .background{
            font-size: 14px;
            font-style: normal;
            font-weight: 400;            
            color: var(--dark-text-grey, #595F6A);
            padding-right: 10px;
        }
    }
}

.backbtn{
    border-radius: 8px;
    background: var(--dark-input-button, #1D2025);
    border: 0;
    width: 40px;
    height: 40px;
    text-align: center;
}

.paraTxt p{
    color: var(--dark-text-secondary, #C5C5C5);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}