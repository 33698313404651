

.cl_banner{
    position: relative;
    &::before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: calc(100% - 96px);
        background: linear-gradient(180deg, #090A0C 0%, rgba(9, 10, 12, 0.62) 53.12%, rgba(9, 10, 12, 0.00) 100%);
    }
    &::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, #090A0C 0%, rgba(9, 10, 12, 0.62) 38.19%, rgba(9, 10, 12, 0.00) 100%);
        height: 96px;
    }

    .editIconCnt{
        width:40px;
        height:40px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.16);
        padding:10px;
        /* BG Blur - Button */
        backdrop-filter: blur(19px);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom:20px;
        right:20px;
        z-index: 5;
        display:none;
    }
    &:hover{
        .editIconCnt{
            display: block;

            &:hover{
                cursor: pointer;
            }
        }
    }

    // .coverBannerCnt{
    //     position:relative;
    //     min-height:320px;

    //     .noCoverCnt{
    //         position:absolute;
    //         left:0px;
    //         top:0px;
    //         width:100%;
    //         height:100%;
    //         background: linear-gradient(180deg, #090A0C 0%, rgba(9, 10, 12, 0.62) 53.12%, rgba(9, 10, 12, 0.00) 100%);
    //     }
    // }
}

:global(body.light-mode) {
    .cl_banner{
        position: relative;
        &::before{
            background: linear-gradient(180deg, #090A0C 0%, rgba(9, 10, 12, 0.62) 53.12%, rgba(9, 10, 12, 0.00) 100%);
        }
        &::after{
            display: none;
        }
    }
}
