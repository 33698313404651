// Modal

.curMdl {
   @media only screen and (min-width: 576px) {
      max-width: 258px;
   }

   &.lgMdl{
      @media only screen and (min-width:992px){
         max-width:644px;   
      }      
   }

   border-radius: 12px;

   .modal-content {
      background-color: #15171C;
      padding: 14px;
   }

   .selCrHd {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 14px;

      .btn:first-child {
         opacity: 0;
      }

      .btn {
         padding: 0px;
      }

      span {
         font-size: 16px;
         color: var(--dark-text, #F6F6F6);
         font-weight: 600;
      }
   }
   h3{
      &.walletHeading{
         color: #F6F6F6;
         text-align: center;
         font-size: 20px;
         font-style: normal;
         font-weight: 600;
         line-height: 32px;
      }
   }
   p{
      &.walletSubHeading{
         color: var(--dark-text-secondary, #C5C5C5);
         text-align: center;
         font-size: 16px;
         font-style: normal;
         font-weight: 400;
         line-height: 24px;
      }
   }
}

.selCurBp {
   background: rgba(81, 81, 81, 0.30);
   backdrop-filter: blur(32px);

   &.show {
      opacity: 1;
   }
}


.curSrh {
   border-radius: 8px;
   border: 1px solid var(--dark-borders-button-text-inactive, #2E343D);
   background: var(--dark-input-button, #1F2329);
   backdrop-filter: blur(19px);
   align-items: center;

   .form-control {
      border: 0px;
      background-color: transparent;
      height: 29px;
      color: #ffffff;
      font-size: 14px;

      &:focus {
         box-shadow: none;
         padding-left: 0px;
      }
   }
}

.selCrLs {
   margin: 10px 0px 0px;
   max-height: 170px;
   overflow: auto;

   /* width */
   &::-webkit-scrollbar {
      width: 2px;
   }

   /* Track */
   &::-webkit-scrollbar-track {
      background: #000000;
   }

   /* Handle */
   &::-webkit-scrollbar-thumb {
      background: #1F2329;
   }

   /* Handle on hover */
   &::-webkit-scrollbar-thumb:hover {
      background: #555;
   }

   .btn {
      padding: 6px 8px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      margin: 2px 0px;
      font-size: 14px;
      color: #ffffff;
      border-radius: 8px;

      &:hover {
         background: #1F2329;
      }

      img {
         margin-right: 8px;
      }
   }
}



// *********Bridge**********************

.inrPgMt {
   margin-top: 72px;
}

.inrPgMb {
   margin-bottom: 60px;
}

.mb-35 {
   margin-bottom: 35px;

}

.BriSec {
   padding: 30px 0px;
   background-image: url("./assets/elprd.png");
   background-repeat: no-repeat;
   background-position: 50% 50%;
   background-size: contain;
   position: relative;
   overflow: hidden;

   @media(min-width:1200px){
      min-height:calc(100vh - 772px);
      display:flex;
      align-items: center;  
      justify-content: center;
   }
}

.shdcd {
   position: absolute;
   left: 70%;
   top: 50%;
   transform: translate(-50%, -50%);
}

.brhsd {
   h1 {
      font-family: var(--font-russo);
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 20px;
   }

}

.crdbrg {
   background-color: #15171C;
   padding: 40px 70px;
   border-radius: 10px;
   position: relative;
   z-index: 2;
}

.FrmIpt {
   h1 {
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
   }

   .form-group {
      min-width: calc(100% - 153px);
      margin-right: 15px;

   }

   input {
      border-radius: 12px;
      color: #FFF;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      background: #2E343D;
      height: 60px;
      border: none;
      // min-width: calc(100% - 137px );

      &:focus {
         color: #FFF;
         background: #2E343D;
         border: none;
         box-shadow: none;

      }
   }
}

.sltbtn {
   min-width: 137px;
   min-height: 60px;
   border-radius: 12px;
   background: #2E343D;
   padding: 10px 10px;
   box-shadow: none;
   color: #FFF;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   display: flex;
   align-items: center;
   justify-content: space-between;

   span {
      margin: 0px 3px;
   }

   &:focus {
      box-shadow: none;

   }

   &:hover {
      color: #FFF;

   }
}

.swpbtn {
   min-width: 214px;
   min-height: 43px;
   padding: 8px 15px;
   border-radius: 12px;
   font-size: 16px;
   font-style: normal;
   font-weight: 600;
   color: #0A0A0B;
   background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
   display: flex;
   align-items: center;
   justify-content: center;
   margin: auto;

   &:hover {
      background: linear-gradient(90deg, #FFA900 6.3%, #FFEDAE 95.08%);
   }

}

@media only screen and (max-width: 991px) {
   .crdbrg {
      padding: 30px 25px;
   }

   .sltbtn {
      min-width: 93px;
      min-height: 50px;
      padding: 10px 7px;
      font-size: 15px;

      img {
         width: 23px;
         height: 23px;
      }
   }

   .FrmIpt input {
      font-size: 17px;
      height: 50px;

   }

   .FrmIpt .form-group {
      min-width: calc(100% - 98px);
   }

}



// ********************Bridge History*********************\

.BrigeHises {
   padding: 30px 0px;

}

.britab {
   background-color: #15171C;
   padding: 20px 20px;
   border-radius: 10px;

}

.bri_head {
   tr {
      th {
         color: #595F6A;
         font-size: 12px;
         font-style: normal;
         font-weight: 400;
         text-align: right;
         white-space: nowrap;

         &:nth-child(1) {
            text-align: start;
         }
      }
   }
}

.buy_table_body {
   tr {
      td {
         color: #F6F6F6;
         font-size: 14px;
         font-style: normal;
         font-weight: 400;
         text-align: right;
         padding: 30px 15px;
         white-space: nowrap;

         &:nth-child(1) {
            text-align: start;
         }

         button {
            border-radius: 8px;
            background: #0A0A0B;
            min-width: 103px;
            min-height: 26px;
            color: #484954;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            padding: 2px 10px;
            transition: 0.3s ease-in-out;

            &:hover {
               background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
               color: #0A0A0B;
               transition: 0.3s ease-in-out;
            }
         }

         &.yelcl {
            color: #FFC266 !important;
         }

         &.redcl {
            color: #FA0B00 !important;
         }

         &.grncl {
            color: #1BFA70 !important;
         }
      }
   }

}

.bri_pagenation {
   margin: 20px 0px;

   .pagination {
      .page-link {
         border-radius: 2px;
         background: #15171C;
         border: none;
         color: #FFF;
         font-size: 14px;
         font-style: normal;
         font-weight: 400;
         margin: 2px;
         border-radius: 2px;
         padding: 5px 8px;

         &:hover {
            background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
            color: #000;

         }
      }

      .page-link-1 {
         background-color: transparent;
         color: #FFF;
         font-size: 14px;
         font-style: normal;
         font-weight: 400;
         border: none;

         &:hover {
            background: transparent;
            color: #FFF;
         }
      }
   }
}


body.light-mode{
   .crdbrg, .britab {
      background-color: var(--light-bg-2);
   }
   .FrmIpt{
      h1 {
         color: var(--light-text);
      }
      input{
         background: var(--light-input-button);
         color: var(--light-text);
      }      
   }
   .sltbtn{
      background: var(--light-input-button);
      color: var(--light-text);
      span{
         svg path[stroke= 'white']{
            stroke: var(--light-text)
         }
      }
   }
   .bri_head tr th{
      color: var(--light-text-secondary);
   }
   .buy_table_body tr td {
      color: var(--light-text);
   }
   .buy_table_body tr td button{
      background: var(--light-input-button);
      color: var(--light-text);
      &:hover{
         background: var(--light-gradient-light);         
      }
   }
   .curMdl {
      .modal-content {
         background-color: var(--light-bg-2);
         border: 1px solid rgba(255,255,255,.2);
      }
      .selCrHd span {
         color: var(--light-text);
      }
   }
   .curSrh{
      background: var(--light-input-button);
      border-color: var(--light-borders-hover-button);
      button{
         img{
            filter: brightness(0.3);
         }
      }
      .form-control{
         color: var(--light-text);
      }      
   }
   .selCrLs .btn{
      color: var(--light-text);
      &:hover{
         background-color: var(--light-input-button);
      }
   }
   .bri_pagenation .pagination .page-link{
      background: var(--light-input-button);
      color: var(--light-text);
   }
   .bri_pagenation .pagination .page-link-1{
      color: var(--light-text);
      background-color: transparent;
   }
   
}